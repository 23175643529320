@import "../vars.scss";
.pagehome {
  &__sendbox {
    background: $color-green;
    box-shadow: 0 2px 15px 0 rgba(44, 209, 162, 0.49);
    border-radius: 15px;
    padding: 30px 40px 40px;

    span {
      font-weight: 700;
      font-size: 24px;
      color: #ffffff;
      text-transform: uppercase;
    }

    input[type="text"] {
      background: #ffffff;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.09);
      border-radius: 10px;
      font-weight: 600;
      font-size: 18px;
      height: 50px;
      font-family: Mont;
      line-height: 50px;
      padding: 3px 15px 0;
      width: 100%;
      border: 0;
      outline: none;
      color: $color-text-primary;
      text-transform: uppercase;
      &::placeholder {
        color: #c7c7c7;
      }
    }

    button[type="submit"] {
      background: $color-purple;
      box-shadow: 0 2px 15px 0 rgba(120, 29, 112, 0.35);
      border-radius: 10px;
      font-weight: 600;
      font-size: 16px;
      color: #fff;
      text-align: center;
      height: 50px;
      line-height: 50px;
      padding: 0 15px;
      border: 0;
      width: 160px;
      margin-left: 15px;
      transition: 0.15s ease all;
      font-family: Mont;
      &:disabled {
        opacity: 0.7;
      }
    }
  }

  &__shipment {
    background: $color-purple;
    border-radius: 10px;
    font-size: 16px;
    color: #ffffff;
    text-align: center;
    width: calc(50% - 15px);
    padding: 10px 5px 8px;
    font-size: 13px;
    transition: 0.3s ease all;

    &:hover {
      box-shadow: 0 2px 15px 0 rgba(120, 29, 112, 0.35);
      text-decoration: none;
      color: #fff;
    }

    &__label {
      font-family: Mont;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;
      text-align: center;
      color: #898989;
      display: block;
      margin-top: 50px;
      margin-bottom: 10px;
    }

    &__img {
      max-width: 20px;
    }

    span {
      display: block;
      margin: 12px 0 0;
    }
  }

  &__partners {
    color: #919191;
    font-size: 14px;
    background: url("/package.png");
    background-size: cover;
    background-position: center bottom;
    padding-left: 0;
    border-radius: 5px;
    margin-top: -34px;
    margin-bottom: -15px;
    @media (max-width: 991px) {
      margin: 40px 0 0;
      padding: 0;
    }

    &__content {
      /* Rectangle 8 */
      height: 100%;
      color: #fff;
      padding-top: calc(50% - 140px);
      text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.45);
      background: linear-gradient(
        90deg,
        #ffffff 0.78%,
        rgba(255, 255, 255, 0) 50.4%
      );
      font-weight: 600;

      @media (max-width: 991px) {
        width: calc(100% + 30px);
        background: none;
        padding: 50px 0;
      }
    }

    img {
      width: 150px;
      margin-top: 20px;
    }
  }
}
