@import "../../vars.scss";
@import "./hamburgers.scss";

.headermenu {
  background: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  width: 100vw;
  height: 85px;
  position: fixed;
  top: 5px;
  left: 0;
  z-index: 9999;
  &--env {
    top: 30px;
  }
  &__logo {
    margin-top: 32px;
  }

  &__hamburger {
    position: fixed;
    right: 15px;
    width: 60px;
    padding: 0;
    left: auto;
    transform: scale(0.7);
    margin-top: 12px;

    &:focus,
    &:active {
      outline: none;
      border: none;
    }
  }
  &__menu {
    margin-top: 18px;
    li {
      list-style: none;
      display: inline-block;
      margin: 0;
      padding: 10px 20px;
      a {
        font-family: Mont;
        font-size: 14px;
        text-decoration: none;
        color: $color-text-secondary;
        transition: 0.15s ease color;
        &:hover {
          color: $color-text-primary;
        }

        &.headermenu__menu__item--selected {
          font-weight: 600;
          color: $color-text-primary;
        }
      }

      &.headermenu__menu__item--login {
        background: $color-purple-light;
        border-radius: 10px;
        padding: 10px 23px;
        margin-left: 15px;
        a {
          color: $color-text-purple;
          font-weight: 600;
        }
      }

      &.headermenu__menu__item--loggedin {
        background: $color-purple-light;
        border-radius: 10px;
        padding: 10px 23px;
        margin-left: 15px;
        a {
          color: $color-text-purple;
          font-weight: 800;
        }
      }
    }
    @media (max-width: 991px) {
      position: fixed;
      width: 100%;
      left: 0;
      margin-top: 33px;
      background: #ffffff;
      text-align: left;
      padding: 0px 20px 0;
      height: auto;
      overflow: hidden;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
      border-top: 0px solid #fff;
      max-height: 0;
      transition: 0.3s ease all;

      &.open {
        border-top: 1px solid #eee;
        max-height: 270px;
        padding-bottom: 25px;
      }

      li {
        display: block;
        width: 100%;
        padding: 10px 0;
        border-bottom: 1px solid #ddd;

        &.headermenu__menu__item--login {
          background: $color-purple-light;
          border-radius: 10px;
          padding: 10px 23px;
          margin-left: 0;
          margin-top: 20px;
          border-bottom: none;
          a {
            color: $color-text-purple;
            font-weight: 600;
          }
        }
      }
    }
  }

  &__envbar {
    position: fixed;
    top: 0;
    width: 100%;
    text-align: center;
    height: 30px;
    font-size: 12px;
    background: orangered;
    color: #fff;
    padding-top: 6px;
  }
  &__topbar {
    position: fixed;
    top: 0;
    width: 100%;

    height: 5px;

    background: $color-purple;
  }
}
