.ttstatus {
  color: #333;

  &__currentstatus {
    background: #ffd43a;
    border-radius: 10px;
    text-align: center;
    padding: 10px 10px;
    font-weight: 600;
    color: #555;
    font-size: 14px;

    &__date {
      margin-top: 10px;
      display: block;

      font-style: 300;
      font-size: 12px;
      text-align: center;
      color: #666666;
    }
  }

  &__history-item {
    padding-bottom: 6px;
    margin-bottom: 25px;
    border-bottom: 1px solid #dcdcdc;

    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }

    &__date {
      display: block;
      font-size: 13px;
      color: #666;
    }

    &__description {
      display: block;
      font-weight: 600;
      color: #555;
    }
  }

  &__address {
    padding-bottom: 15px;
    border-bottom: 1px solid #dcdcdc;
    margin-bottom: 35px;
    color: #555;

    strong {
      font-weight: 600;
      display: block;
      margin-bottom: 5px;
    }

    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  .border-top {
    border-top: 1px solid #e7e6e6;
  }

  .boxed-grey {
    background: #efefef;
    border-radius: 20px;
    padding: 30px;
    text-align: left;
    align-self: flex-start;
  }

  h3 {
    font-family: Mont;
    font-weight: 800;
    font-size: 18px;
  }
}
