@import "../../vars.scss";

.container-content {
  background: #ffffff;
  box-shadow: 0 2px 15px 0 rgba(206, 206, 206, 0.49);
  border-radius: 15px;
  padding: 30px;
  font-size: 0.9rem;

  h1:not(.h1-home) {
    font-family: Mont;
    font-weight: 900;
    font-size: 26px;
    color: $color-text-primary;
    letter-spacing: -0px;
    margin: 0;
  }

  h2:not(.h2-home) {
    font-family: Mont;
    font-weight: 900;
    font-size: 18px;
    color: $color-purple;
    letter-spacing: -0px;
    margin: 0;
  }

  .order__subtitle {
    font-size: 14px;
    color: #9c9c9c;

    &--green {
      color: $color-green;
    }
  }
}

.orderoverview {
  &--update {
    color: $color-green;
    font-weight: normal;
    font-size: 13px;
    text-decoration: underline;
    margin-left: 10px;
    margin-top: 2px;
    position: absolute;
    display: inline-block;
  }
}

.orderpayment {
  &__method {
    display: inline-block;
    vertical-align: text-top;
    width: calc(25% - 15px);
    font-size: 14px;
    color: #444444;
    border: none;
    border: 2px solid #fff;

    box-shadow: 0 1px 14px 0 rgba(0, 0, 0, 0.15);
    padding: 10px;
    border-radius: 10px;
    margin-right: 20px;
    transition: all 0.2s ease;

    &:hover {
      transform: scale(1.05);
      color: inherit;
      text-decoration: none;
    }

    &--selected {
      border: 2px solid $color-green;
      transform: scale(1.05);
    }

    @media (max-width: 576px) {
      margin-right: 0;
      width: 100%;
      margin-top: 10px;
    }

    &:last-child {
      margin-right: 0;
    }

    img {
      width: 40px;
      margin-bottom: 10px;
    }

    select {
      width: 100%;
      height: 40px;
      border: 1px solid $color-border-form;
      padding: 0 10px;
      margin-top: 10px;
      border-radius: 5px;
    }
  }
}

.boxed-grey {
  padding: 20px;
  background: #f5f5f5;
  border-radius: 10px;
}

.form-radio {
  span {
    color: #333;
  }
}
