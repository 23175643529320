@import "../../vars.scss";
footer,
.footer {
  background: #ffffff;
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.02);
  width: 100vw;
  height: 120px;
  margin-top: 120px;

  &__links a {
    color: #666;
    font-size: 14px;
  }

  &__copyright {
    color: #999;
    font-size: 14px;
  }
}
