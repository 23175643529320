.activatecode {
  height: 80px !important;
  font-size: 40px !important;
  font-weight: 800 !important;
  letter-spacing: 20px;
  font-family: Mont !important;
  width: 295px;
  display: inline;
  padding-left: 20px;
  padding-top: 15px;
  text-align: center;
}
