.loader {
  width: 60px;
  height: 60px;
  background: url("/loader-darkgrey.svg");
  background-size: 60px 60px;
  display: block;
  margin-left: calc(50% - 30px);
  margin-top: 50px;
  margin-bottom: 50px;

  &--nopadding {
    margin: 0;
    // background-size: 50px 50px;
  }

  &--right-70 {
    position: absolute;
    right: 70px;
  }

  &--white {
    background-image: url("/loader-white.svg");
  }
}
