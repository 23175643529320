input.form-control {
  height: 45px;
  border-radius: 10px;
  border-color: $color-border-form;
  font-size: 15px;
  line-height: 45px;
  @media (max-width: 576px) {
    font-size: 16px;
  }
}

select.form-control {
  background-position: right calc(0.375em + 1.7875rem) center !important;
  height: 45px;
}

textarea.form-control {
  border-radius: 10px;
  border-color: $color-border-form;
  font-size: 15px;
  @media (max-width: 576px) {
    font-size: 16px;
  }
}

.form-check-label {
  color: $color-text-primary;
  font-size: 14px;
  margin-bottom: 3px;
  margin-left: 5px;
}

input[type="checkbox"],
input[type="radio"] {
  width: 15px;
  height: 15px;
  margin-right: 20px;
  margin-top: 6px;
  margin: 6px 20px 0px -15px;
  border-color: $color-border-form;
}

.form-control {
  &.is-valid {
    border-color: $color-green;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%232cd1a2' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    &:focus {
      border-color: $color-green;
      box-shadow: 0 0 0 0.2rem rgba(44, 209, 162, 0.25);
    }
  }
  &.is-invalid {
    border-color: $color-warning;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23ff8c5e' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ff8c5e' stroke='none'/%3e%3c/svg%3e");

    &:focus {
      border-color: $color-warning;
      box-shadow: 0 0 0 0.2rem #ff8c5e50;
    }
  }

  &.shortvalidation {
    background: none !important;
    padding-right: 10px !important;
  }

  &-loading {
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 25px;
      height: 25px;
      background: url("/loader-darkgrey.svg");
      background-size: 25px 25px;
      top: 37px;
      right: 25px;
    }
  }
}

.form-group {
  label:not(.form-check-label) {
    font-size: 13px;
    color: $color-text-secondary;
    letter-spacing: -0px;
    margin-left: 5px;
    margin-bottom: 5px;
  }

  .form-error {
    color: $color-warning;
    margin-left: 5px;
    font-size: 12px;
  }
}

@keyframes btn-animate-in {
  from {
    opacity: 0;
    top: -35px;
  }
  to {
    top: 7px;
    opacity: 1;
  }
}

.btn {
  border-radius: 10px;
  height: 45px;
  min-width: 170px;
  border-radius: 10px;
  border: 0;
  font-family: Mont;
  font-weight: 600;
  overflow: hidden;
  transition: all 0.2s ease;

  font-size: 14px;
  line-height: 45px;
  padding: 2px 15px;

  @media (max-width: 576px) {
    width: 100%;
  }

  &-loading {
    padding-top: 43px;
    position: relative;

    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 30px;
      height: 30px;
      background: url("/91.svg");
      background-size: 30px 30px;
      top: -30px;
      left: calc(50% - 15px);
      animation: btn-animate-in 0.2s ease forwards;
    }
  }

  &-primary {
    background: $color-green;
    color: #fff;
    &:hover:not(:disabled),
    &:active {
      background: #02ab7b !important;
      border: none;
      box-shadow: none !important;
    }
    &:focus {
      background: $color-green;
      box-shadow: none !important;
    }
    &:disabled,
    &--loading {
      background: #68e7c3;
    }
  }

  &-outline {
    &-primary {
      border: 1px solid $color-green;
      color: $color-green;
      box-sizing: border-box;
      padding-top: 0;

      &:hover {
        background: $color-green;
        border-color: $color-green;
      }
    }
  }
}

.alert {
  border-radius: 10px;
  color: #fff;
  border: 0;
  margin: 0;
  padding: 9px 15px 10px;
  overflow: hidden;
  animation: alert-in 2.2s ease forwards;

  &-auto {
    width: auto;
    display: inline-block;
  }

  &-warning {
    background: #ff8c5e;
    font-size: 14px;
  }

  &-success {
    background: $color-green;
    font-size: 14px;
  }

  &-success-outline {
    background: none;
    font-size: 14px;
    border: 1px solid $color-green;
    color: $color-green;
  }

  &-info {
    background: #8ca4b4;
  }

  &-success-outline {
    background: none;
    font-size: 14px;
    border: 1px solid $color-green;
    color: $color-green;
  }

  a {
    color: #fff;
    text-decoration: underline;
  }
}

.input-group-append {
  border-left: none;
}
.input-group-text {
  border-radius: 10px;
  border-left: none;
  background: #f1f1f1;
}

.w-sm-auto {
}
