@font-face {
  font-family: "Mont";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Mont Book"), local("Mont-Book"), url("assets/fonts/Mont-Book.woff2") format("woff2");
}

@font-face {
  font-family: "Mont";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Mont Regular"), local("Mont-Regular"), url("assets/fonts/Mont-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Mont";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local("Mont SemiBold"), local("Mont-SemiBold"), url("assets/fonts/Mont-SemiBold.woff2") format("woff2");
}

@font-face {
  font-family: "Mont";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local("Mont Bold"), local("Mont-Bold"), url("assets/fonts/Mont-Bold.woff2") format("woff2");
}
