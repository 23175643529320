@import "./vars.scss";
@import "./fonts.scss";
@import "./theme.scss";

.verzenden-app {
  padding-top: 140px;
  min-height: calc(100vh - 240px);

  @media (max-width: 576px) {
    padding-top: 100px;
    min-height: calc(100vh - 205px);
  }
}

h1 {
  font-weight: 900;
  font-size: 36px;
  line-height: 34px;
  font-family: Mont;
}

h2 {
  font-weight: 900;
  font-size: 24px;
  font-family: Mont;
}
.headerlink {
  display: inline-block;
  color: #888;

  &:hover {
    color: #888;
  }
}
.text-green {
  color: $color-green;
}

.text-gray {
  color: #888;
}

.text-dark-gray {
  color: rgb(106, 105, 105);
}

.text-light-gray {
  color: rgb(154, 154, 154);
}

.text-purple {
  color: $color-purple;
}
.text-uppercase {
  text-transform: uppercase;
}
