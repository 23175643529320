@import "../../vars";

.container-my {
  padding-top: 0;
}

.my {
  &-menu {
    margin: 0 -30px;
    background: #727272;
    list-style: none;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .col {
      display: block;
      text-align: center;

      a {
        font-size: 14px;
        font-family: Mont;
        font-weight: 600;
        color: #fff;
        display: block;
        height: 60px;
        padding: 22px;
        position: relative;
        overflow: hidden;

        &:hover {
          text-decoration: none;
        }
        &:after {
          position: absolute;
          width: 80%;
          left: 10%;
          bottom: -10px;
          height: 8px;
          background: #9a9a9a;
          content: "";
          display: block;
          border-top-left-radius: 15px;
          border-top-right-radius: 15px;
          opacity: 0;
          transition: all 0.15s ease;
        }

        &.active:after,
        &:hover:after {
          bottom: 0px;
          opacity: 1;
        }
      }
    }
  }

  &-table__wrapper {
    border: 1px solid #ddd;
    border-radius: 10px;
    overflow: hidden;
  }

  &-table {
    width: 100%;
    border-radius: 10px;
    display: table;
    box-sizing: content-box;

    &__header {
      background: #ffd43a;

      th {
        padding: 15px 15px;
        color: #555555;
        font-weight: normal;
      }
    }
    tr {
      td {
        vertical-align: text-top;
        color: #555555;
        padding: 15px 15px;
        border-top: 1px solid #ddd;

        a {
          color: #555555;

          &.cta {
            color: $color-purple;
            font-weight: 600;

            &--large {
              font-size: 16px;
              // font-weight: 900;
            }
          }
        }

        .label-success {
          color: #0cbb77;
          font-weight: 600;
        }

        .label-note {
          color: #aaa;
        }

        .label-warning {
          color: #f26601;
          font-weight: 600;
        }

        .label-danger {
          color: #f20101;
          font-weight: 600;
        }

        .label-progress {
          color: #008cff;
          font-weight: 600;
        }
      }
    }

    &__skeleton {
      height: 20px;
      width: 100%;
      background: #ededed;
      display: block;
      border-radius: 5px;
      background-image: linear-gradient(
        90deg,
        #f4f4f4 0px,
        rgba(233, 233, 233, 0.932) 40px,
        #f4f4f4 80px
      );
      background-size: 600px;
      animation: shine-lines 1s infinite ease-out;
    }
  }

  &-order-total {
    background: #f6ecf7;
    color: #6e276d;
    padding: 20px;
    border-radius: 10px;
  }

  &-dashboard {
    &__highlight {
      & > a {
        display: block;
        background: #008cff;
        color: #fff;
        padding: 25px 25px 17px;
        border-radius: 20px;
        font-family: Mont;
        margin: 0 10px;
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
        cursor: pointer;

        &:hover {
          text-decoration: none;
        }
      }

      &__count {
        font-weight: 900;
        font-size: 50px;
        display: block;
        line-height: 50px;
      }

      &__title {
        font-weight: 600;
        font-size: 16px;
        line-height: 15px;
      }

      &--yellow > a {
        background: #ffd43a;
      }

      &--purple > a {
        background: #6e276d;
      }

      &--green > a {
        background: #66cda5;
      }
    }
  }
}

@keyframes shine-lines {
  0% {
    background-position: -100px;
  }
  70%,
  100% {
    background-position: 440px;
  }
}

a.shipment-floater,
.shipment-floater {
  background: #66cda5;
  max-width: 50px;
  width: 100%;
  height: 50px;
  border-radius: 34px;
  padding: 0;
  display: block;
  position: fixed;
  z-index: 99;
  bottom: 50px;
  right: 50px;
  box-shadow: 0px 0px 10px #66cda6b9;
  transition: all 0.2s ease;
  text-decoration: none !important;
  overflow: hidden;

  &.active {
    display: none;
  }

  &__txt {
    color: #fff;
    font-family: Mont;
    font-weight: 600;
    font-size: 16px;
    text-decoration: none !important;
    line-height: 52px;
    position: absolute;
    right: 52px;
    width: 200px;
    text-align: right;
    opacity: 0;
    transition: all 0.2s ease;
    transform: translateX(60px);
  }

  &__img {
    width: 25px;
    height: 25px;
    transition: transform 0.2s ease;
    position: absolute;
    right: 12px;
    top: 12px;
  }

  &:hover {
    // transform: scale(1);
    max-width: 195px;
    right: 50px;

    .shipment-floater__img {
      transform: rotate(90deg);
    }

    .shipment-floater__txt {
      transform: translateX(0);
      opacity: 1;
    }
  }
}
