.mydropdown {
  width: 100%;
  background: #fff;
  height: 45px;
  border-radius: 10px;
  border: 1px solid #d3d3d3;

  &__list {
    position: relative;
    top: 0px;
    background: #fff;
    box-shadow: 0 2px 15px 0 rgba(206, 206, 206, 0.49);
    border: 1px solid #d3d3d3;
    border-radius: 10px;
    overflow: scroll;
    max-height: 0px;
    opacity: 0;
    transition: all 0.2s ease;
    z-index: 999;

    &--open {
      max-height: 190px;
      opacity: 1;
    }

    &__item {
      // height: 35px;
      border-bottom: 1px solid #d3d3d3;
      padding: 10px;
      color: #444;
      font-size: 13px;
      cursor: pointer;
      strong {
        font-weight: 600;
      }

      &--highlighted {
        background: #727272;
        color: #fff;
      }

      &:last-child {
        border: none;
      }
    }
  }

  &__selected {
    padding: 13px 15px;
    display: block;
    position: absolute;
    color: #444;
    font-size: 13px;
  }
  &__search {
    width: 100%;
    height: 43px;
    border-radius: 10px;
    border: none;
    padding-left: 10px;
    z-index: 999;
    background: #fff;
    position: relative;
    z-index: 999;
    transition: all 0.2s ease;
    // margin-top: -43px;
    &:focus,
    &:active {
      border: none;
      outline: none;
    }
    opacity: 0;

    &:focus {
      opacity: 1;
    }
  }
}

.hr-text {
  text-align: center;
  width: auto;
  &:before {
    width: 100%;
    height: 1px;
    position: relative;
    display: block;
    content: "";
    background: #c0c0c0;
    top: 12px;
  }

  span {
    background: #f5f5f5;
    position: relative;
    padding: 0 10px;
    color: #7a7a7a;
  }

  &--white span {
    background: #fff;
  }
}
