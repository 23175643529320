@import "../../../vars.scss";

.faqitem {
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  margin-bottom: 10px;
  &__question {
    font-size: 16px;
    font-weight: 500;
    color: #333;
    display: block;
    padding: 15px;
    cursor: pointer;
    transition: color 0.2s ease;
    width: 100%;
    &:hover {
      text-decoration: none !important;
      color: $color-purple;
    }
  }
  &__answer {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
    padding: 0 15px 0;
    font-size: 16px;
  }
  &--open {
    .faqitem__answer {
      max-height: 300px;
    }
  }
  .faq-search-filter {
    background-color: $color-purple;
    color: #fff;
    padding: 3px 4px;
    border-radius: 3px;
  }
}
