.myorderitem {
  background: #f5f5f5;
  border: 1px solid #e9e9e9;
  box-sizing: border-box;
  border-radius: 10px;
  width: 100%;
  padding: 25px;
  margin-top: 30px;
  opacity: 0;
  animation: itemIn 0.6s ease forwards;

  &--addingmode {
    background: #e9e9e9;
    text-align: center;
    padding: 0;

    button {
      border: none;
      background: none;
      width: 100%;
      height: 100%;
      padding: 25px;

      &:focus,
      &:active {
        border: none;
        outline: none;
      }

      span {
        margin-left: 15px;
        color: #888888;
        font-weight: 600;
      }
    }
  }

  &__price {
    color: #2cd1a2;
    font-weight: bold;
    font-size: 20px;
  }

  &__price-container {
    padding: 20px;
    background: #fff;
    border-radius: 15px;
    margin-bottom: 60px;
  }

  &__btn_add_order {
    position: absolute;
    bottom: 0;
    width: calc(100% + 0px);
    left: 0px;
    align-self: flex-end;
    margin-top: 40px;
    bottom: -60px;
  }

  &view {
    background: #fafafa;
    border: 1px solid #e9e9e9;
    box-sizing: border-box;
    border-radius: 10px;
    width: 100%;
    padding: 25px;
    margin-top: 30px;

    transition: 0.5s ease all;
    max-height: 400px;
    overflow: hidden;

    opacity: 0;
    animation: itemIn 0.6s ease forwards;

    &--removed {
      transform: scaleY(0);
      opacity: 0;
      max-height: 0;
      margin: 0;
      padding: 0;
    }

    &__ctabar {
      background: #ffd43a;
      color: #494949;
      width: 100%;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      position: relative;
      left: -25px;
      width: calc(100% + 50px);
      bottom: -25px;
      padding: 10px 30px;
      text-align: right;
      // font-weight: 700;
      font-size: 15px;

      a {
        color: #333;
      }
    }
  }

  &save {
    background: #f5f5f5;
    border: 1px solid #e9e9e9;
    box-sizing: border-box;
    border-radius: 10px;
    width: 100%;
    padding: 25px;
    margin-top: 30px;
    opacity: 0;
    animation: itemIn 0.6s ease forwards;
    background: #e9e9e9;
    text-align: center;
    padding: 0;

    button {
      border: none;
      background: none;
      width: 100%;
      height: 100%;
      padding: 25px;

      &:focus,
      &:active {
        border: none;
        outline: none;
      }

      span {
        margin-left: 15px;
        color: #888888;
        font-weight: 600;
      }
    }

    &__error {
      color: #888888;
      padding: 20px 0;
      display: block;
    }
  }
}

@keyframes itemIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
